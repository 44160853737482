<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <Message />
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 12px">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-layout class="fill-height overflow-y-hidden px-5" column>
            <div
              style="
                  flex: none;
                  height: 88px;
                  width: 100%;
                  text-align: center;
                  border-bottom: 1px solid rgba(93, 101, 136, 0.5);
                "
            >
              <v-layout align-center class="fill-height px-5">
                <v-btn class="mr-2" dark fab small @click="$router.push('/admin')">
                  <v-icon>icon-arrow_left</v-icon>
                </v-btn>
                <h3 class="text-uppercase">LOG VIEW</h3>
                <v-spacer />
                <v-btn tile @click="goPage">
                  <v-icon left>
                    mdi-fullscreen
                  </v-icon>
                  Open in fullscreen
                </v-btn>
              </v-layout>
            </div>
            <div class="pt-5 pb-5" style="height: 100%">
              <iframe
                :src="logviewLink"
                style="height: 100%; width: 100%; border-radius: 15px;"
                title="W3Schools Free Online Web Tutorials"
              ></iframe>
            </div>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
  </v-layout>
</template>

<script>
import Message from '@/components/Message.vue'
import ConvertDate from '@/utils/convertDate'

import { mapState } from '@/store/ults'

export default {
  name: 'User',
  components: {
    Message,
  },
  data() {
    return {
      ConvertDate,
      loading: false,
      logviewLink: process.env.VUE_APP_LOGVIEW,
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },

  mounted() {},
  methods: {
    goPage() {
      window.open(this.logviewLink, process.env.VUE_APP_LOGVIEW)
    },
  },
}
</script>

<style scoped></style>
